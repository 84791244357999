<template>
  <div>

    <h3 class='game-header'>{{pageTitle}}</h3>

    <CInput class='search-game' placeholder='Search by game title' v-model='currentSearch' @input='changeSearch'/>

    <CRow :gutters='false'>
      <CCol col='6' sm='3' md='3' lg='2' v-for='(game, index) in games' :key='game._id' class='game-item' >
        
        <div v-if='!game.isImageLoaded'>
          <div class='sq'>
            <div class='sqcontent'>
              <vue-skeleton-loader rounded type='rect' height='100%' width='100%' radius='4' color='#251146' animation='fade'/>
            </div>
          </div>
          <div style='margin-top:10px'>
            <vue-skeleton-loader rounded type='rect' height='24px' width='100%' radius='10' color='#251146' animation='fade'/>
          </div>
          <div style='margin-top: 10px;margin-bottom:15px'>
            <vue-skeleton-loader rounded type='rect' height='11px' width='50%' radius='30' color='#251146' animation='fade'/>
          </div>
        </div>

        <div v-show='game.isImageLoaded' @click='launchGame(game)'>
          <img v-if="game.provider == 'PS'" :src="'https://cdn.s777.club/web/playstar/' + game.providerID + '.jpeg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded' />
          <img v-if="game.provider == 'JK'" :src="'https://cdn.s777.club/web/joker/' + game.providerID + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'FK'" :src="'https://cdn.s777.club/web/funky/' + game.providerID + '.jpg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'BG'" :src="'https://cdn.s777.club/web/bgaming/' + game.providerID + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'OP'" :src="'https://cdn.s777.club/web/onlyplay/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'KG'" :src="'https://cdn.s777.club/web/kaga/' + game.providerID.replace(':', '') + '.png'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>
          <img v-if="game.provider == 'VP'" :src="'https://cdn.s777.club/web/vpower/' + game.providerID + '.jpg'" @load='imageOnLoad(index)' class='game-banner img-fluid w-100 rounded'/>

          <div class='game-overlay'>
            <CLink><img src='https://cdn.s777.club/web/play-icon.png'/></CLink>
            <CLink>
              <span v-if='isLoggedIn'>Play</span>
              <span v-if='!isLoggedIn'>Demo</span>
            </CLink>
          </div>
          <div class='game-name'>{{game.name}}</div>
          <div class='game-provider' v-if="game.provider == 'PS'">Playstar</div>
          <div class='game-provider' v-if="game.provider == 'JK'">Joker</div>
          <div class='game-provider' v-if="game.provider == 'FK'">Funky</div>
          <div class='game-provider' v-if="game.provider == 'BG'">Bgaming</div>
          <div class='game-provider' v-if="game.provider == 'OP'">Onlyplay</div>
          <div class='game-provider' v-if="game.provider == 'KG'">Kaga</div>
          <div class='game-provider' v-if="game.provider == 'VP'">Vpower</div>
        </div>
      </CCol> 
    </CRow>

    <div style='width:100%;text-align: center;' v-if='runningCount < totalCount'>
      <CButton shape='pill' size='lg' class='show-all' @click="loadMore">Show More</CButton>
    </div>

    <div v-if='games.length == 0' class='no-games'>
      <img src='https://cdn.s777.club/web/cat-head.png'/>
      <span>Oops, no games found for the search "<strong style='color:yellow'>{{currentSearch}}</strong>".</span>
    </div>

  </div>
</template>

<script>
import apiGames from '@/api/games'
import { EventBus } from '@/event-bus.js'
import { debounce } from 'debounce';

export default {
  name: 'Games',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  data () {
    return {
      pageTitle: 'Games',
      games: [],
      currentPage: 1,
      currentSort: 'name',
      currentSearch: null,
      currentGameIndex: 0,
      runningCount: 0,
      totalCount: 0,
      gameLaunched: false,
      dbtype: null
    }
  },
  created() {
    EventBus.$on('closeGame', () => {
      this.gameLaunched = false
    })
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length == 0){
          this.currentSort = 'name'
          this.currentSearch = null
        }else{
          if(newVal.sort) { this.currentSort = newVal.sort }
          if(newVal.search) { this.currentSearch = newVal.search }
        }
        var gametype = this.$route.params.gametype

        this.currentPage = 1
        this.games = []
        this.currentGameIndex = 0
        this.runningCount = 0

        if(gametype == 'all'){
          this.pageTitle = 'All Games'
          this.fetchList(this.currentPage, 48, this.currentSort, this.currentSearch)
        }else{
          var dbtype = '' // System types: SLT (Slots) , FTB (Fish Tables) , ARC (Arcade), LOT (Lottery - Bingo, Keno) , ECA (ECasino) , OTH (Others)
          if(gametype == 'slots'){
            dbtype = 'SLT' 
            this.pageTitle = 'Slots'
          }
          if(gametype == 'fish-tables'){
            dbtype = 'FTB'
            this.pageTitle = 'Fish Tables'
          }
          if(gametype == 'arcade'){
            dbtype = 'ARC'
            this.pageTitle = 'Arcade'
          }
          if(gametype == 'sweepstakes'){
            dbtype = 'LOT'
            this.pageTitle = 'Sweepstakes'
          }
          if(gametype == 'e-casino'){
            dbtype = 'ECA'
            this.pageTitle = 'E-Casino'
          }
          if(gametype == 'others'){
            dbtype = 'OTH'
            this.pageTitle = 'Others'
          }
          this.dbtype = dbtype
          this.fetchList(this.currentPage, 48, this.currentSort, this.currentSearch, dbtype)
        }
      }
    }
  },
  methods: {
    fetchList(page, limit, sort, search, dbtype = null) {

      var startIndex = this.currentGameIndex
      var endIndex = parseInt(this.currentGameIndex) + 48

      // load 48 empty items into games array
      for (let i = 0; i < 48; i++) {
        var index = i + this.currentGameIndex
        this.games.splice(index, 1, {})
      }

      // load games
      apiGames.listGames(page, limit, sort, search, dbtype).then(response => {
        this.totalCount = response.data.count
        this.runningCount += 48
        var extraItems = 0
        if(this.runningCount > this.totalCount){
          extraItems = this.runningCount - this.totalCount 
        }
        var games = response.data.data
        for (let i = 0; i < 48; i++) {
          if(games[i]){
            var index = i + this.currentGameIndex
            games[i].isImageLoaded = false
            this.games.splice(index, 1, games[i])
          }else{
            break
          }
        }

        if(extraItems > 0){
          var deleteIndex = this.runningCount - extraItems
          this.games.splice(deleteIndex, extraItems)
        }

        // update current game index
        this.currentGameIndex += 48
      }).catch((err) => {
        this.$toast.error('Error loading games. Please refresh the page and try again.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
      })
    },
    loadMore() {
      this.currentPage += 1
      //this.$router.push({ name: 'AllGames', query: { page: this.currentPage, sort: this.currentSort, search: this.currentSearch } })
      if(this.dbtype && this.dbtype !== ''){
        this.fetchList(this.currentPage, 48, this.currentSort, this.currentSearch, this.dbtype)
      }else{
        this.fetchList(this.currentPage, 48, this.currentSort, this.currentSearch)
      }
    },
    imageOnLoad(index){
      this.games[index].isImageLoaded = true
    },
    launchGame(game){
      if(!this.gameLaunched){
        this.gameLaunched = true
        EventBus.$emit('launchGame', game)
      }
      //EventBus.$emit('launchGame', game)
    },
    changeSearch: debounce(function (val) {
      this.currentSearch = val
      this.currentPage = 1
      this.games = []
      this.currentGameIndex = 0
      this.runningCount = 0
      this.$router.push({ name: 'Games', query: { sort: this.currentSort, search: this.currentSearch }, params: { gametype: this.$route.params.gametype } })
    }, 1000)
  }
}
</script>
